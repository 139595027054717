import '@xyflow/react/dist/style.css';

// https://github.com/twbs/examples/blob/main/webpack/src/js/main.js
//
// Import our custom CSS
import '../scss/project.scss';

// Import only the Bootstrap components we need
import {Popover} from 'bootstrap'

// Create an example popover
document.querySelectorAll('[data-bs-toggle="popover"]')
    .forEach(popover => {
        new Popover(popover)
    })
